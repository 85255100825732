"use strict";

var $ = require("jquery");
var ApiUser = require("./ApiUser");

var Cookies = require("cookies-js");
window.Cookies = Cookies;
var is_debug = _AUTOGENERATE_IS_DEBUG_MODE_;
window.is_debug = is_debug;

var use_debug_strings = is_debug;
var extend = require("extend");

var BootstrapDialog = require("bootstrap3-dialog");

var PageHeader = require("./PageHeader");
var common = require("./common");

var switch_old_route = require("./MetronicFrontend_old_routing");

const PUBLIC_JDP2_PAGES = new Set([
	"package_list",
	"package_buy",
	"package_buy_paypal_return",
	"package_preview",
	"test_papers_buy_one"
]);

const JDP2_PAGES_WAIVE_ACTIVATION = new Set([
	"package_buy_paypal_return",
	"package_buy_stripe",
	"package_buy",
	"package_list",
	"package_preview",
	"package_thank_you",
	"package_must_activate",
]);

const JDP_TO_JDP2_MAP = {
	"marks": "marks",
}

function add_flag_to_sidebar(entry) {
	const flag = $("<div/>").text("⬤").css({
		"color": "red",
		"position": "absolute",
		top: 7,
		left: 15,
	});
	$(entry).append(flag);
}


class MetronicFrontend {
	constructor() {
		this.state = {
			api_base: "/api",
			api_user: new ApiUser(this),
			res: res_compiled,
			data_json: data_json,
			page_id: null,
			page_header: new PageHeader()
		};

		let current_language = Cookies.get("lang");
		if (!current_language) {
			current_language = "en";
			Cookies.set("lang", current_language, { expires: new Date("9999-09-09") });
		}

		let choose_lang = $("#choose-lang");
		for (let lang in this.state.res.other) {
			let opt = $("<option/>").text(lang).val(lang);
			if (lang == this.state.current_language) {
				opt.attr("selected", "selected");
			}
			choose_lang.append(opt);
		}

		choose_lang.on("change", () => {
			Cookies.set("lang", choose_lang.val(), { expires: new Date("9999-09-09") });
			window.location = window.location;
		});

		this.state.current_language = current_language;

		window.au = this.state.api_user;
		window.api_user = this.state.api_user;
		

		window.mf = this;

		// shortcut for string fetch
		window.nss = this.get_nss_from_sid_args.bind(this);
		window.old_string = this.get_string.bind(this);
	}

	_init_page_handler_class() {
		let page_handler_class = null;

		// Public pages (login, register, unauthed video browsing) shouldn't make
		// any API requests that need auth. If they do, the user is immediately
		// sent to the login page.
		this.state.is_public_page = false;

		// Original routing system, see also JDP below
		switch (window.location.pathname) {
			case "/jdp":
				const jdp_page = common.get_args(document.location.href)["page"];
				const jdp2replacement = JDP_TO_JDP2_MAP[jdp_page];

				if (jdp2replacement) {
					window.location.replace("/jdp2?page=" + jdp2replacement);
					return;
				} else {
					page_handler_class = require("./JDP");

					if (window.location.href.includes("course_search_ent")
						|| window.location.href.includes("login_from_connect")) {
						this.state.is_public_page = true;
					}
				}



				break;
			case "/jdp2":
				page_handler_class = require("./JDP2");
				const jdp2_page = common.get_args(document.location.href)["page"];


				if (PUBLIC_JDP2_PAGES.has(jdp2_page)) {
					this.state.is_public_page = true;
				} else {
					$("#quick-access").css("display", "block");
				}


				break;
			default: {
				const old_route = switch_old_route(window.location.pathname);
				page_handler_class = old_route[0];
				this.state.is_public_page = old_route[1];
				break;
			}

		}

		this.page_handler_class = (page_handler_class._exported_class || page_handler_class);
		console.log(this.page_handler_class);

		if (page_handler_class.custom_on_auth_failed_handler) {
			this.state.api_user.set_custom_on_auth_failed_handler(page_handler_class.custom_on_auth_failed_handler);
		}
	}

	async _init_features_and_strings() {
		this.state.nss_strings = await this.state.api_user.fetch_data_promise("/nss_get_strings?lang=" + this.state.current_language, null);
		this.state.feature_config = await this.state.api_user.fetch_data_promise(this.state.is_public_page ? "/auth/get_features_config_unauthed" : "/auth/get_features_config", null);
		this.state.is_logged_in = this.state.feature_config.is_logged_in;
		
		window["_feature_config"] = this.state.feature_config;
		window.currency = this.state.nss_strings[106]["_currency"];
		window.is_logged_in = this.state.is_logged_in;
	}

	_init_maybe_enforce_activation() {
		if (this.state.feature_config.must_change_password && window.location.pathname != "/request_password_reset" && window.location.pathname != "/activate" && window.location.pathname != "/login") {
			window.location = "/request_password_reset?mode=confirm&variant=first_login";
		}

		if (this.state.feature_config.must_activate) {
			let should_redirect_for_activation;

			if (window.location.pathname.startsWith("/jdp2")) {
				let jdp2_page = common.get_args(document.location.href)["page"];

				should_redirect_for_activation = !JDP2_PAGES_WAIVE_ACTIVATION.has(jdp2_page);

			} else if (window.location.pathname == "/request_password_reset" || window.location.pathname == "/login" || window.location.pathname == "/activate") {
				should_redirect_for_activation = false;
			} else {
				should_redirect_for_activation = true;
			}

			if (should_redirect_for_activation) {
				window.location = "/jdp2?page=package_must_activate&return=" + encodeURIComponent(document.location.pathname + document.location.search);
			}
		}
	}


	async init_stage_2() {
		this._init_page_handler_class();
		await this._init_features_and_strings();
		this._init_maybe_enforce_activation();
	}

	get_nss_from_sid(sid) {
		let type;
		let name;

		let sid_split = sid.split(":");
		if (sid_split.length == 2) {
			type = parseInt(sid_split[0]);
			name = sid_split[1];
		} else {
			type = 106;
			name = sid_split[0];
		}

		if (this.state.nss_strings[type]) {
			let val = this.state.nss_strings[type][name];
			if (val) {
				if (use_debug_strings) {
					return "(NS) " + val;
				} else {
					return val;
				}
			}
		}

		return (use_debug_strings ? "NOSTRING: " : "") + name;
	}

	get_nss_from_sid_args(sid, args_arr) {
		let str = this.get_nss_from_sid(sid);

		let nssArgs = [];
		if (arguments.length == 2 && Array.isArray(arguments[1])) {
			nssArgs = arguments[1];
		} else if (arguments.length > 1) {
			nssArgs = [...arguments].slice(1);
		}

		for (let i in nssArgs) {
			let arg = nssArgs[i];
			str = str.replace(new RegExp("\\{" + i + "\\}", "g"), arg);
		}

		return str;
	}

	_init_stage_3_strings() {
		$("[data-string]").each((_, e) => {
			let id = $(e).attr("data-string");


			// let str = res.en[id];
			let str = this.get_string(id);

			let attr_name = $(e).attr("data-string-attr-name");

			if (attr_name) {
				$(e).attr(attr_name, str);
			} else {
				$(e).text(str);
			}
		});

		$("[data-nss]").each((_, e) => {
			let sid = $(e).attr("data-nss");
			if (!sid) {
				return;
			}

			let args = [];

			const allArgs = $(e).attr("data-nss-args");
			if (allArgs) {

				// const str_from_menu_config = this.state.feature_config.text_options[from_menu_config];
				// if (str_from_menu_config) {
				// 	str = str_from_menu_config;
				// }

				args = allArgs.split(",").map(arg => {
					if (arg.includes("|")) {
						const [portalMenuStr, nssStr] = arg.split("|", 2);
						const portalMenuVal = this.state.feature_config.text_options[portalMenuStr];
						return portalMenuVal || this.get_nss_from_sid(nssStr);
					} else {
						return this.get_nss_from_sid(arg);
					}
				});
			} else {
				let argN = 0;
				while (1) {
					let arg = $(e).attr("data-nss-arg" + argN);
					if (!arg) {
						break;
					}
	
					args.push(arg);
					argN++;
				}
			}



			let str = this.get_nss_from_sid_args(sid, args);

			const from_menu_config = $(e).attr("data-string-from-menu-config");

			if (from_menu_config) {
				const str_from_menu_config = this.state.feature_config.text_options[from_menu_config];
				if (str_from_menu_config) {
					str = str_from_menu_config;
				}
			}

			let attr_name = $(e).attr("data-nss-attr-name");

			if (attr_name) {
				$(e).attr(attr_name, str);
			} else {
				$(e).text(str);
			}
		});
	}

	_init_stage_3_welcome_popup() {
		const popup_exclude = new Set(["/login", "/request_password_reset", "/activate", "/create_account"]);
		const is_excluded_page = popup_exclude.has(document.location.pathname);

		const is_new = !!Cookies.get("is_new");

		// welcome_message_ack_for_session is a session cookie
		if (!Cookies.get("welcome_message_ack_for_session") && this.state.is_logged_in && !is_excluded_page) {

			this.state.api_user.fetch_data("/auth/get_login_details", null, (err, message_data) => {

				const effectiveMsg = is_new ? message_data.message : message_data.existing_user_message;
				if (!effectiveMsg)
					return;

				if (is_new && message_data.ack_data && message_data.ack_data.welcome1_ack) {
					Cookies.set("welcome_message_ack_for_session", true);
					return;
				}

				if (!is_new && message_data.ack_data && message_data.ack_data.welcome2_ack) {
					Cookies.set("welcome_message_ack_for_session", true);
					return;
				}


				BootstrapDialog.show({
					message: effectiveMsg.replace("$user_name$", Cookies.get("user_id")),
					title: this.get_string("intro_welcome"),
					closable: false,
					cssClass: "very-high-zindex",
					type: BootstrapDialog.TYPE_INFO,
					buttons: [
						{
							label: this.get_string("intro_remind_me"),
							action: function (dialogItself) {
								Cookies.set("welcome_message_ack_for_session", true);
								dialogItself.close();
							}
						},
						{
							label: this.get_string("intro_understood"),
							action: function (dialogItself) {
								api_user.post_data(
									"/auth/set_message_ack",
									{
										msgType: is_new ? 1 : 2,
									},
									(err, res) => {
										Cookies.set("welcome_message_ack_for_session", true);
										dialogItself.close();
									}
								);

							}
						}
					]
				});
			});
		}

	}

	_init_stage_3_load_page() {
		const on_page_load_error = (err) => {
			console.log(err);

			let serialErr = "<unknown>";
			try {
				serialErr = JSON.stringify(err, Object.getOwnPropertyNames(err), 4);
			} catch (_) {
				// Do nothing.
			}

			BootstrapDialog.show({
				message: "An error occurred while loading the page.\n\nDetails: " + serialErr,
				title: "Error",
				cssClass: "very-high-zindex",
				type: BootstrapDialog.TYPE_WARNING,
				buttons: [
					{
						label: "OK",
						action: function(dialogItself){
							dialogItself.close();
							window.location = window.location;
						}
					}
				]
			});

		};

		const page_handler = new this.page_handler_class(this);
		if (page_handler.load_async) {
			page_handler.load_async().then(_ => {
				this.fill_height(".page-sidebar, #quick-access");
			}, on_page_load_error);
		} else {
			page_handler.load(() => {
				this.fill_height(".page-sidebar, #quick-access");
			});
		}
	}

	_init_stage_3_populate_quick_access() {
		let find_block = block_name => $("#" + block_name + "-qa-block");
		let find_with_data_attr = (attr, val) => $("[data-" + attr + "=" + val + "]");

		let fetch_qa_data = (endpoint, heading, cb) => {

			let when_done = (err, data) => {
				let block = find_block(heading);
				let left_menu_entry = find_with_data_attr("requires-permission", heading);
				if (err) {
					block.remove();
					left_menu_entry.remove();
				} else {
					cb(data, result => {
						if ((result === null) || (typeof result == "undefined")) {
							block.remove();
						} else {
							if (!block.hasClass("hide-because-lacks-feature")) {
								block.removeClass("hide");
							}
							block.find(".qa-heading").text(result);
							if (!left_menu_entry.hasClass("hide-because-lacks-feature")) {
								left_menu_entry.removeClass("hide");
							}
						}
					});
				}
			};

			if (!this.state.is_logged_in) {
				when_done("no_login", null);
			}
			else if (endpoint == "dummy") {
				when_done(null, "");
			} else {
				this.state.api_user.fetch_data(endpoint, null, function (err, data) {
					when_done(err, data);
				});
			}
		};

		fetch_qa_data("/student/profile/get_my_profile", "profile", (data, cb) => {
			if (data.fields_data && (data.fields_data.name || data.fields_data.user_id)) {
				let name = data.fields_data.name || data.fields_data.user_id;
				$(".data-student-name").text(name);
				this.state.page_header.layout();
				$(window).trigger("resize");

				for (let n of [20, 100, 100, 100, 1000]) {
					window.setTimeout(_ => {
						$(window).trigger("resize");
					}, n);
				}

				cb(name);
			} else {
				cb(null);
			}
		});


		if (this.state.is_logged_in) {
			this.state.api_user.fetch_data("/student/comms/get_has_any_unread_comms", null, (err, are_any_unread) => {
				if (are_any_unread) {
					add_flag_to_sidebar($(".page-sidebar [data-requires-permission=comms]"));
				}
			});
		}

		console.log("pathname: " + window.location.pathname);
		if (window.location.pathname == "/student/fees") {
			fetch_qa_data("dummy", "fees", (total, cb) => {
				cb("");
			});
		} else {
			fetch_qa_data("/student/fees_rewrite/get_not_paid_total", "fees", (total, cb) => {
				cb(window.currency + total.toFixed(2));
			});
		}

		fetch_qa_data("/student/attendance/get_makeup_all?return_count_only=true", "makeups", (data, cb) => {
			cb(data.length || null);
		});

		fetch_qa_data("/student/course/get_course_all?return_count_only=true", "courses", (data, cb) => {
			cb(data || null);
		});

		fetch_qa_data("/student/course/get_lesson_all?return_count_only=true&include_sessions=true", "lessons", (data, cb) => {
			var lesson_count = data || 0;
			if (this.state.feature_config.features.includes("HideCalendar") && lesson_count > 0) {
				$("[data-requires-features=Lessons]").removeClass("hide");
			}
			cb(lesson_count || null);
		});

		fetch_qa_data("/student/my_videos_rewrite/get_my_videos_count_rewrite", "videos", (data, cb) => {
			cb(data || null);
		});

		fetch_qa_data("/student/photos/get_all_photos?return_count_only=true", "photos", (data, cb) => {
			let video_count = data || 0;
			if (video_count > 0) { cb(video_count); } else { cb(null); }
		});


		fetch_qa_data("/student/package_iec/get_my_bookings?return_count_only=true", "bookings", (data, cb) => {
			cb(data || null);
		});


		fetch_qa_data("/student/package_iec/get_my_packages?return_count_only=true", "credits", (data, cb) => {
			let total = data || 0;
			cb(total || null);
		});

		fetch_qa_data("/student/fees_rewrite/stripe_get_has_saved_card", "credit_card", (data, cb) => {
			cb(data ? 1 : null);
		});
	}

	_init_stage_3_on_ready() {
		this.fill_height(".page-sidebar, #quick-access");

		let setup_done = false;

		const onResize = () => {
			this.fill_height(".page-sidebar, #quick-access");
			if (setup_done) {
				this.state.page_header.layout();
			}
		};

		$(window).resize(onResize);
		$(document).resize(onResize);

		this.state.page_header.setup($(".page-header-inner"));
		setup_done = true;

		let feature_config = this.state.feature_config;

		let features = feature_config.features;
		let f1s = feature_config.f1_permissions;

		$("[data-requires-features]").each((_, req_elem) => {
			let reqs_string = $(req_elem).attr("data-requires-features");
			if ((!reqs_string) || (reqs_string.length < 1)) {
				return;
			}

			let reqs = reqs_string.split(",");

			let all_satisfied = true;

			for (let req of reqs) {
				let negate = !!(req.startsWith("!"));
				if (negate)
					req = "" + (req.substr(1));
				let found = (features.indexOf(req) != -1);

				if ((!found && !negate) || (found && negate)) {
					all_satisfied = false;
				}
			}

			if (all_satisfied) {
				if (!$(req_elem).hasClass("dont-unhide-because-feature")) {
					$(req_elem).removeClass("hide");
				}

			} else {
				$(req_elem).addClass("hide hide-because-lacks-feature");
			}
		});

		$("[data-requires-f1-permission]").each((_, req_elem) => {
			let reqs_string = $(req_elem).attr("data-requires-f1-permission");
			if ((!reqs_string) || (reqs_string.length < 1)) {
				return;
			}

			let satisfied = f1s.indexOf(reqs_string) != -1;

			if (!satisfied) {
				// this made the log too cluttered
				// console.log("Permission not satisfied: " + reqs_string + ", but will continue.");

				// TODO: Was this intentionally commented out?
				//$(req_elem).addClass("hide");
			}

		});

		if (window.location.pathname.indexOf("/login") != -1) {
			console.log(features);
			if (features.indexOf("VideosPortal") != -1 && (features.indexOf("FindCourse") != -1 || features.indexOf("BuyCourse") != -1)) {
				$(".continue-guest > p > a").removeClass("disabled").text(this.get_nss_from_sid("103:continue_guest"));
			}
		}


		this._init_stage_3_welcome_popup();

		if (this.state.feature_config.custom_links) {
			for (let custom_link of this.state.feature_config.custom_links) {
				let li = $("<li/>");
				li.append($("<a/>").attr("target", "_blank").attr("href", custom_link.url).text(custom_link.name));
				$(".page-sidebar-menu [data-section=student] .sub-menu").append(li);
			}
		}

		if (this.state.feature_config.chat_enabled && this.state.is_logged_in) {
			let li = $("<li/>");
			li.append($("<a/>").attr("target", "_blank").attr("href", "/api/student/chat/get_redirect_to_chat").text(nss("102:chat_btn")));
			$(".page-sidebar-menu [data-section=student] .sub-menu").append(li);

			if (this.state.feature_config.chat_unread) {
				add_flag_to_sidebar(li);
			}
		}

		if (this.state.feature_config.features.includes("TodaysLesson")) {
			let li = $("<li/>");
			li.addClass("todays_lesson_btn_li");
			// .attr("target", "_blank")
			li.append($("<a/>").attr("id", "todays_lesson_btn_link").attr("href", "/jdp2?page=lesson_today").text(nss("102:lesson_today_btn")));
			$(".page-sidebar-menu [data-section=student] .sub-menu").append(li);
		}

		this._init_stage_3_load_page();

		this._init_stage_3_populate_quick_access();

		if (!this.state.is_logged_in) {
			$("[data-section=student]").hide();
			$(".data-student-name").text(this.get_string("create_account"));
			$(".data-student-name").parent().attr("href", "/create_account");
		}

		var sidebar_visible = true;

		var content_classes_when_sidebar_visible = "";
		var content_classes_when_sidebar_hidden = "";

		var initiallyHideSidebar = document.location.pathname == "/jdp2"
			&& (
				document.location.search.startsWith("?page=package_buy_paypal_return")
				|| document.location.search.startsWith("?page=package_preview")
			);

		if (!this.state.is_logged_in || initiallyHideSidebar) {
			sidebar_visible = false;
			$(".page-sidebar-wrapper").addClass("hidden");

			if (!this.state.is_logged_in) {
				$("#sidebar-toggler-in-header").addClass("hidden");
			}
		} else {
			$(".page-sidebar-wrapper").removeClass("hidden");
		}


		function sidebar_toggle() {
			if (sidebar_visible) {
				$(".page-sidebar-wrapper").addClass("hidden");

				$(".page-content-wrapper")
					.removeClass(content_classes_when_sidebar_visible)
					.addClass(content_classes_when_sidebar_hidden);

				sidebar_visible = false;
			} else {
				$(".page-sidebar-wrapper").removeClass("hidden");

				$(".page-content-wrapper")
					.removeClass(content_classes_when_sidebar_hidden)
					.addClass(content_classes_when_sidebar_visible);

				sidebar_visible = true;
			}
		}

		if ($(window).width() < 992 && (this.state.is_logged_in && !initiallyHideSidebar)) {
			sidebar_toggle();
		}

		this.state.page_header.layout();

		setTimeout(() => {
			this.state.page_header.layout();
		}, 250);

		$(".menu-toggler-v2").click(_ => {
			sidebar_toggle();
			this.state.page_header.layout();
		});

		$("#logout-button").click(function () {
			console.log("Logout event");
			Cookies.set("token", null);
			Cookies.expire("welcome_message_ack_for_session");
			window.location = "/login";
		});
	}

	init_stage_3() {
		if (!this.page_handler_class) {
			return;
		}

		let page_id = window.location.pathname.split("/")[2];
		if (!page_id) {
			page_id = "other";
			this.state.page_res = this.state.res.other;
		} else {
			$("[data-requires-permission=" + page_id + "]").addClass("active");
			console.log(this.state.res.other);
			console.log(this.state.res[page_id]);
			this.state.page_res = extend(true, {}, this.state.res.other, this.state.res[page_id]);

		}
		this.state.page_id = page_id;
		console.log(page_id);

		this._init_stage_3_strings();

		$(document).ready(() => {
			this._init_stage_3_on_ready();
		});
	}

	fill_height(selectors) {
		var pcw_height = $(".page-content-wrapper").height() + 35;
		var visible_height = $(window).height() - $(".page-header").first().height();

		$(selectors).css(
			"min-height",
			Math.max(pcw_height, visible_height) + "px"
		);
		this.state.page_header.layout();
	}
	
	get_string(str_id) {
		if (is_debug && this.state.current_language == "gibberish") {
			return "O_" + new Array(10).join().replace(/(.|$)/g, function () { return ((Math.random() * 36) | 0).toString(36)[Math.random() < .5 ? "toString" : "toUpperCase"](); });
		} else {
			return (use_debug_strings ? "OLDSTRING: " : "") + this.state.page_res[this.state.current_language][str_id];
		}
	}
}








module.exports = MetronicFrontend;



